<script>
import AntInput from '@/components/AntInput.vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import { mapGetters } from 'vuex';
import FileHandlerService from '@/services/file-handler';
import {
  phaseOptions,
  wkbTaskTableName,
} from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkb.utils';
import DhmeWkbPhaseChip from '@/modules/daiwa-house-modular-europe/DhmeWkbPhaseChip.vue';

export default {
  name: 'DhmeWkbTasksLibrary',
  components: { DhmeWkbPhaseChip, DeleteDialog, DynamicDataTable, AntInput },
  data: () => {
    return {
      tableRecords: [],
      tableHeaders: [
        {
          text: 'Measurement',
          value: 'measurement_id',
          hasSlot: true,
        },
        {
          text: 'Task Nr.',
          value: 'number',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phase',
          value: 'phase',
          hasSlot: true,
        },
        {
          text: 'BG',
          value: 'export_bg',
          hasSlot: true,
          width: '100px',
        },
        {
          text: 'Warranty',
          value: 'export_warranty',
          hasSlot: true,
          width: '100px',
        },
        {
          text: 'Contract',
          value: 'export_contract',
          hasSlot: true,
          width: '100px',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      wkbTaskItem: {},
      wkbTaskDialogDisplayed: false,
      wkbTaskDeleteDialogDisplayed: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      measurementFilter: '',
    };
  },
  computed: {
    ...mapGetters([
      'wkbLibraryMeasurements',
      'wkbLibraryMeasurementTasks',
      'wkbLibraryMeasurementTasksTableId',
      'wkbLibraryTableStatus',
      'project',
    ]),

    taskMeasurements() {
      return this.wkbLibraryMeasurements
        .filter((m) => m.type === 'task')
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    filteredTasks() {
      if (this.measurementFilter) {
        return this.wkbLibraryMeasurementTasks.filter(
          (f) => f.measurement_id === this.measurementFilter
        );
      }

      return this.wkbLibraryMeasurementTasks;
    },
  },
  methods: {
    phaseOptions() {
      return phaseOptions;
    },
    closeWkbTasksDialog() {
      this.wkbTaskItem = Object.assign({}, {});
      this.wkbTaskDialogDisplayed = false;
      this.wkbTaskDeleteDialogDisplayed = false;
      this.$refs['create-wkb-task-form'].reset();
    },
    setupEdit(record) {
      this.wkbTaskItem = Object.assign({}, record);
      this.wkbTaskDialogDisplayed = true;
    },
    setupDelete(record) {
      this.wkbTaskItem = Object.assign({}, record);
      this.wkbTaskDeleteDialogDisplayed = true;
    },
    async saveOrUpdateWkbTasks() {
      if (this.$refs['create-wkb-task-form'].validate()) {
        if (this.wkbTaskItem.id) {
          // update item
          const recordId = this.wkbTaskItem.id;
          delete this.wkbTaskItem.id;
          if (this.wkbTaskItem.default instanceof File) {
            await FileHandlerService.handleFile(this.wkbTaskItem.default).then(
              (value) => {
                this.wkbTaskItem.default = value;
              }
            );
          }
          this.$store
            .dispatch('updateWkbLibraryRecord', {
              table: wkbTaskTableName,
              recordId,
              body: {
                record: this.wkbTaskItem,
              },
            })
            .then(() => {
              this.closeWkbTasksDialog();
            });
        } else {
          // create item
          if (this.wkbTaskItem.default instanceof File) {
            await FileHandlerService.handleFile(this.wkbTaskItem.default).then(
              (value) => {
                this.wkbTaskItem.default = value;
              }
            );
          }
          this.$store
            .dispatch('createWkbLibraryRecord', {
              table: wkbTaskTableName,
              body: {
                record: this.wkbTaskItem,
              },
            })
            .then(() => {
              this.closeWkbTasksDialog();
            });
        }
      }
    },
    deleteWkbTasksRecord() {
      this.$store
        .dispatch('deleteWkbLibraryRecord', {
          table: wkbTaskTableName,
          recordId: this.wkbTaskItem.id,
        })
        .then(() => {
          this.closeWkbTasksDialog();
        });
    },
  },
};
</script>

<template>
  <div class="ant-glass-background radius-0">
    <dynamic-data-table
      :can-delete="true"
      :can-edit="true"
      :is-loading="wkbLibraryTableStatus === 'loading'"
      :project-id="project.id"
      :table-columns="[{ name: 'default', type: 'task' }]"
      :table-headers="tableHeaders"
      :table-id="wkbLibraryMeasurementTasksTableId"
      :table-records="filteredTasks"
      table-title=""
      @deleteItem="setupDelete"
      @editItem="setupEdit"
    >
      <template #table-buttons>
        <v-autocomplete
          v-model="measurementFilter"
          :items="taskMeasurements"
          clearable
          dense
          filled
          flat
          hide-details
          item-text="name"
          item-value="id"
          placeholder="Measurement"
          style="max-width: 300px !important"
        />
      </template>
      <template #table-actions>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ant-icon"
              @click="wkbTaskDialogDisplayed = true"
              v-on="on"
            >
              mdi-plus
            </v-icon>
          </template>
          <span>Add task</span>
        </v-tooltip>
      </template>
      <template #item.measurement_id="{ item, rowId, value }">
        {{ taskMeasurements.find((m) => m.id === value)?.name }}
      </template>
      <template #item.phase="{ item, rowId, value }">
        <dhme-wkb-phase-chip :text="value?.substring(5)" :type="value" />
      </template>
      <template #item.export_bg="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
      <template #item.export_warranty="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
      <template #item.export_contract="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
    </dynamic-data-table>
    <v-dialog
      key="wkb-task"
      v-model="wkbTaskDialogDisplayed"
      max-width="600"
      @click:outside="closeWkbTasksDialog"
      @keydown.esc="closeWkbTasksDialog"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline">
          {{ wkbTaskItem.id ? 'Update' : 'Add' }} Task
        </v-card-title>
        <v-divider />
        <div class="px-10 pb-5">
          <v-form ref="create-wkb-task-form" @submit.prevent>
            <ant-input label="Measurement">
              <template #input-field>
                <v-autocomplete
                  v-model="wkbTaskItem.measurement_id"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  :items="taskMeasurements"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="id"
                  placeholder="Measurement"
                />
              </template>
            </ant-input>

            <div class="d-flex">
              <ant-input class="mr-5" label="Tasks nr" style="flex: 1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbTaskItem.number"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    :rules="[rules.required]"
                    dense
                    filled
                    hide-details
                    placeholder="Number"
                  />
                </template>
              </ant-input>

              <ant-input label="Name" style="flex: 2">
                <template #input-field>
                  <v-text-field
                    v-model="wkbTaskItem.name"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    :rules="[rules.required]"
                    dense
                    filled
                    hide-details
                    placeholder="Name"
                  />
                </template>
              </ant-input>
            </div>

            <ant-input label="Phase">
              <template #input-field>
                <v-autocomplete
                  v-model="wkbTaskItem.phase"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  :items="phaseOptions()"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="id"
                  placeholder="Phase"
                />
              </template>
            </ant-input>
            <div class="d-flex justify-center">
              <ant-input class="flex-1" is-optional label="Export BG">
                <template #input-field>
                  <v-checkbox
                    v-model="wkbTaskItem.export_bg"
                    :value="false"
                    dense
                    hide-details
                  />
                </template>
              </ant-input>
              <ant-input
                class="mx-5 flex-1"
                is-optional
                label="Export Warranty"
              >
                <template #input-field>
                  <v-checkbox
                    v-model="wkbTaskItem.export_warranty"
                    :value="false"
                    dense
                    hide-details
                  />
                </template>
              </ant-input>
              <ant-input class="flex-1" is-optional label="Export Contract">
                <template #input-field>
                  <v-checkbox
                    v-model="wkbTaskItem.export_contract"
                    :value="false"
                    dense
                    hide-details
                  />
                </template>
              </ant-input>
            </div>
          </v-form>
        </div>
        <v-card-actions class="ant-border-top ant-dialog-actions-bg">
          <v-spacer />
          <v-btn
            :disabled="wkbLibraryTableStatus === 'loading'"
            class="mx-2"
            color="error"
            small
            text
            @click="closeWkbTasksDialog"
            >cancel
          </v-btn>
          <v-btn
            :disabled="wkbLibraryTableStatus === 'loading'"
            :loading="wkbLibraryTableStatus === 'loading'"
            color="primary"
            small
            @click="saveOrUpdateWkbTasks"
            >{{ wkbTaskItem.id ? 'update' : 'create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :dialog="wkbTaskDeleteDialogDisplayed"
      title="Are you sure you want to delete this record?"
      @closeDialog="closeWkbTasksDialog"
      @deleteAction="deleteWkbTasksRecord"
    />
  </div>
</template>

<style lang="scss" scoped></style>
