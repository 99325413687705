<template>
  <div v-if="wkbLibraryStatus === 'success'" class="d-flex flex-column">
    <module-navigation-bar title="WKB Library">
      <template #module-nav-actions>
        <v-tabs
          v-model="wkbLibraryTab"
          background-color="transparent"
          height="46"
          show-arrows
          style="width: auto; max-width: calc(100vw - 400px)"
        >
          <v-tab> Risks</v-tab>
          <v-tab> Measurements</v-tab>
          <v-tab> Forms</v-tab>
          <v-tab> Documents</v-tab>
          <v-tab> Tasks</v-tab>
        </v-tabs>
      </template>
      <template slot="module-nav-logo">
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div
      class="flex-1 flex-scroll-height overflow-y-auto overflow-x-auto"
      style="max-width: 100vw"
    >
      <router-view />
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center">
    <ant-loading />
  </div>
</template>

<script>
import AntLoading from '@/components/AntLoading.vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import { DHME_WKB_LIBRARY } from '@/modules/modules';

export default {
  name: 'DhmeWkbLibrary',
  components: {
    ModuleNavigationBar,
    AntLoading,
  },
  data: () => {
    return {
      wkbLibraryTab: 0,
    };
  },
  computed: {
    ...mapGetters(['project', 'wkbLibraryStatus', 'isDaiwaLicense']),
  },
  watch: {
    wkbLibraryTab(value) {
      switch (value) {
        case 0: {
          this.$router.push('risks');
          break;
        }
        case 1: {
          this.$router.push('measurements');
          break;
        }
        case 2: {
          this.$router.push('forms');
          break;
        }
        case 3: {
          this.$router.push('documents');
          break;
        }
        case 4: {
          this.$router.push('tasks');
          break;
        }
      }
    },
  },
  mounted() {
    this.$store
      .dispatch('fetchWkbLibraryModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === DHME_WKB_LIBRARY
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      })
      .then(() => {
        if (this.$route.name === DHME_WKB_LIBRARY) {
          this.$router.push({ name: `${DHME_WKB_LIBRARY}-risks` });
        } else {
          switch (this.$route.name) {
            case `${DHME_WKB_LIBRARY}-risks`:
              this.wkbLibraryTab = 0;
              break;
            case `${DHME_WKB_LIBRARY}-measurements`:
              this.wkbLibraryTab = 1;
              break;
            case `${DHME_WKB_LIBRARY}-forms`:
              this.wkbLibraryTab = 2;
              break;
            case `${DHME_WKB_LIBRARY}-documents`:
              this.wkbLibraryTab = 3;
              break;
            case `${DHME_WKB_LIBRARY}-tasks`:
              this.wkbLibraryTab = 4;
              break;
          }
        }
      });
  },
};
</script>

<style lang="scss" scoped></style>
