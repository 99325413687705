<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    @click:outside="closeWKBRiskDialog"
    @keydown.esc="closeWKBRiskDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        {{ wkbRiskItem.id ? 'Update' : 'Add' }} Risk
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <v-form ref="create-wkb-risk-form" @submit.prevent>
          <div class="d-flex">
            <ant-input class="mr-5" label="Number" style="flex: 1">
              <template #input-field>
                <v-text-field
                  v-model="wkbRiskItem.number"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  placeholder="Number"
                  type="number"
                />
              </template>
            </ant-input>

            <ant-input label="Name" style="flex: 2">
              <template #input-field>
                <v-text-field
                  v-model="wkbRiskItem.name"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  placeholder="Name"
                />
              </template>
            </ant-input>
          </div>

          <ant-input is-optional label="Description">
            <template #input-field>
              <v-textarea
                v-model="wkbRiskItem.description"
                :disabled="wkbLibraryTableStatus === 'loading'"
                filled
                hide-details
                placeholder="Please add a description here"
              />
            </template>
          </ant-input>

          <div class="d-flex">
            <ant-input class="mr-5 flex-1" label="Chapter">
              <template #input-field>
                <v-text-field
                  v-model="wkbRiskItem.chapter"
                  dense
                  disabled
                  filled
                  hide-details
                  placeholder="Chapter"
                  type="number"
                />
              </template>
            </ant-input>

            <ant-input class="flex-1" label="Subchapter">
              <template #input-field>
                <v-text-field
                  v-model="wkbRiskItem.sub_chapter"
                  dense
                  disabled
                  filled
                  hide-details
                  placeholder="Subchapter"
                  type="number"
                />
              </template>
            </ant-input>
          </div>

          <ant-input label="NLSFB">
            <template #input-field>
              <v-text-field
                v-model="wkbRiskItem.NLSFB"
                dense
                filled
                hide-details
                placeholder="NLSFB"
              />
            </template>
          </ant-input>
        </v-form>
      </div>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          :disabled="wkbLibraryTableStatus === 'loading'"
          class="mx-2"
          color="error"
          small
          text
          @click="closeWKBRiskDialog"
          >cancel
        </v-btn>
        <v-btn
          :disabled="wkbLibraryTableStatus === 'loading'"
          :loading="wkbLibraryTableStatus === 'loading'"
          color="primary"
          small
          @click="saveOrUpdateWKBRisk"
          >{{ wkbRiskItem.id ? 'update' : 'create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DhmeWkbRisksDialog',
  components: { AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
    chapter: {
      type: Object,
      required: true,
    },
    updateRisk: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      wkbRiskItem: {},
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    ...mapGetters(['wkbLibraryRisks', 'wkbLibraryTableStatus']),

    showDialog() {
      return this.displayed;
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.wkbRiskItem.chapter = this.chapter.chapter;
        this.wkbRiskItem.sub_chapter = this.chapter.sub_chapter;
      }
    },
    updateRisk(value) {
      if (value) {
        this.wkbRiskItem = Object.assign({}, value);
      }
    },
  },
  methods: {
    closeWKBRiskDialog() {
      this.wkbRiskItem = Object.assign({}, {});
      this.$refs['create-wkb-risk-form'].reset();
      this.$emit('closeDialog');
    },
    saveOrUpdateWKBRisk() {
      if (this.$refs['create-wkb-risk-form'].validate()) {
        if (this.wkbRiskItem.id) {
          // update item
          const recordId = this.wkbRiskItem.id;
          delete this.wkbRiskItem.id;
          this.$store
            .dispatch('updateWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_RISKS',
              recordId,
              body: {
                record: this.wkbRiskItem,
              },
            })
            .then(() => {
              this.closeWKBRiskDialog();
            });
        } else {
          // create item
          this.$store
            .dispatch('createWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_RISKS',
              body: {
                record: this.wkbRiskItem,
              },
            })
            .then(() => {
              this.closeWKBRiskDialog();
            });
        }
      }
    },
  },
};
</script>

<style scoped></style>
