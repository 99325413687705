<template>
  <div class="ant-glass-background radius-0">
    <dynamic-data-table
      :can-delete="true"
      :can-edit="true"
      :is-loading="wkbLibraryTableStatus === 'loading'"
      :project-id="project.id"
      :table-columns="[{ name: 'default', type: 'document' }]"
      :table-headers="tableHeaders"
      :table-id="wkbLibraryMeasurementDocumentsTableId"
      :table-records="filteredDocuments"
      table-title=""
      @deleteItem="setupDelete"
      @editItem="setupEdit"
    >
      <template #table-buttons>
        <v-autocomplete
          v-model="measurementFilter"
          :items="documentMeasurements"
          clearable
          dense
          filled
          flat
          hide-details
          item-text="name"
          item-value="id"
          placeholder="Measurement"
          style="max-width: 300px !important"
        />
      </template>
      <template #table-actions>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ant-icon"
              @click="wkbDocumentDialogDisplayed = true"
              v-on="on"
            >
              mdi-plus
            </v-icon>
          </template>
          <span>Add document</span>
        </v-tooltip>
      </template>
      <template #item.measurement_id="{ item, rowId, value }">
        {{ documentMeasurements.find((m) => m.id === value)?.name }}
      </template>
      <template #item.phase="{ item, rowId, value }">
        <dhme-wkb-phase-chip :text="value?.substring(5)" :type="value" />
      </template>
      <template #item.export_bg="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
      <template #item.export_warranty="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
      <template #item.export_contract="{ item, rowId, value }">
        <v-simple-checkbox :value="!!value" disabled />
      </template>
    </dynamic-data-table>
    <v-dialog
      key="wkb-document"
      v-model="wkbDocumentDialogDisplayed"
      max-width="600"
      @click:outside="closeWKBDocumentDialog"
      @keydown.esc="closeWKBDocumentDialog"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase headline">
          {{ wkbDocumentItem.id ? 'Update' : 'Add' }} Document
        </v-card-title>
        <v-divider />
        <div class="px-10 pb-5">
          <v-form ref="create-wkb-document-form" @submit.prevent>
            <ant-input label="Measurement">
              <template #input-field>
                <v-autocomplete
                  v-model="wkbDocumentItem.measurement_id"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  :items="documentMeasurements"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="id"
                  placeholder="Measurement"
                />
              </template>
            </ant-input>

            <div class="d-flex">
              <ant-input class="mr-5" label="Document nr" style="flex: 1">
                <template #input-field>
                  <v-text-field
                    v-model="wkbDocumentItem.number"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    :rules="[rules.required]"
                    dense
                    filled
                    hide-details
                    placeholder="Number"
                  />
                </template>
              </ant-input>

              <ant-input label="Name" style="flex: 2">
                <template #input-field>
                  <v-text-field
                    v-model="wkbDocumentItem.name"
                    :disabled="wkbLibraryTableStatus === 'loading'"
                    :rules="[rules.required]"
                    dense
                    filled
                    hide-details
                    placeholder="Name"
                  />
                </template>
              </ant-input>
            </div>

            <ant-input label="Phase">
              <template #input-field>
                <v-autocomplete
                  v-model="wkbDocumentItem.phase"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  :items="phaseOptions()"
                  :rules="[rules.required]"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="id"
                  placeholder="Phase"
                />
              </template>
            </ant-input>

            <ant-input is-optional label="Default">
              <template #input-field>
                <v-file-input
                  v-model="wkbDocumentItem.default"
                  :disabled="wkbLibraryTableStatus === 'loading'"
                  dense
                  filled
                  hide-details
                  placeholder="document"
                />
              </template>
            </ant-input>

            <div class="d-flex justify-center">
              <ant-input class="flex-1" is-optional label="Export BG">
                <template #input-field>
                  <v-checkbox
                    v-model="wkbDocumentItem.export_bg"
                    :value="false"
                    dense
                    hide-details
                  />
                </template>
              </ant-input>
              <ant-input
                class="mx-5 flex-1"
                is-optional
                label="Export Warranty"
              >
                <template #input-field>
                  <v-checkbox
                    v-model="wkbDocumentItem.export_warranty"
                    :value="false"
                    dense
                    hide-details
                  />
                </template>
              </ant-input>
              <ant-input class="flex-1" is-optional label="Export Contract">
                <template #input-field>
                  <v-checkbox
                    v-model="wkbDocumentItem.export_contract"
                    :value="false"
                    dense
                    hide-details
                  />
                </template>
              </ant-input>
            </div>
          </v-form>
        </div>
        <v-card-actions class="ant-border-top ant-dialog-actions-bg">
          <v-spacer />
          <v-btn
            :disabled="wkbLibraryTableStatus === 'loading'"
            class="mx-2"
            color="error"
            small
            text
            @click="closeWKBDocumentDialog"
            >cancel
          </v-btn>
          <v-btn
            :disabled="wkbLibraryTableStatus === 'loading'"
            :loading="wkbLibraryTableStatus === 'loading'"
            color="primary"
            small
            @click="saveOrUpdateWKBDocument"
            >{{ wkbDocumentItem.id ? 'update' : 'create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :dialog="wkbDocumentDeleteDialogDisplayed"
      title="Are you sure you want to delete this record?"
      @closeDialog="closeWKBDocumentDialog"
      @deleteAction="deleteWkbDocumentRecord"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FileHandlerService from '@/services/file-handler';
import AntInput from '@/components/AntInput.vue';
import { value } from 'lodash/seq';
import { phaseOptions } from '@/modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkb.utils';
import DhmeWkbPhaseChip from '@/modules/daiwa-house-modular-europe/DhmeWkbPhaseChip.vue';

export default {
  name: 'DhmeWkbDocumentsLibrary',
  components: { DhmeWkbPhaseChip, AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      tableRecords: [],
      tableHeaders: [
        {
          text: 'Measurement',
          value: 'measurement_id',
          hasSlot: true,
        },
        {
          text: 'Document Nr.',
          value: 'number',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phase',
          value: 'phase',
          hasSlot: true,
        },
        {
          text: 'Default',
          value: 'default',
        },
        {
          text: 'BG',
          value: 'export_bg',
          hasSlot: true,
          width: '100px',
        },
        {
          text: 'Warranty',
          value: 'export_warranty',
          hasSlot: true,
          width: '100px',
        },
        {
          text: 'Contract',
          value: 'export_contract',
          hasSlot: true,
          width: '100px',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      wkbDocumentItem: {},
      wkbDocumentDialogDisplayed: false,
      wkbDocumentDeleteDialogDisplayed: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      measurementFilter: '',
    };
  },
  computed: {
    ...mapGetters([
      'wkbLibraryMeasurements',
      'wkbLibraryMeasurementDocuments',
      'wkbLibraryMeasurementDocumentsTableId',
      'wkbLibraryTableStatus',
      'project',
    ]),

    documentMeasurements() {
      return this.wkbLibraryMeasurements
        .filter((m) => m.type === 'document')
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    filteredDocuments() {
      if (this.measurementFilter) {
        return this.wkbLibraryMeasurementDocuments.filter(
          (f) => f.measurement_id === this.measurementFilter
        );
      }

      return this.wkbLibraryMeasurementDocuments;
    },
  },
  methods: {
    phaseOptions() {
      return phaseOptions;
    },
    closeWKBDocumentDialog() {
      this.wkbDocumentItem = Object.assign({}, {});
      this.wkbDocumentDialogDisplayed = false;
      this.wkbDocumentDeleteDialogDisplayed = false;
      this.$refs['create-wkb-document-form'].reset();
    },
    setupEdit(record) {
      this.wkbDocumentItem = Object.assign({}, record);
      this.wkbDocumentDialogDisplayed = true;
    },
    setupDelete(record) {
      this.wkbDocumentItem = Object.assign({}, record);
      this.wkbDocumentDeleteDialogDisplayed = true;
    },
    async saveOrUpdateWKBDocument() {
      if (this.$refs['create-wkb-document-form'].validate()) {
        if (this.wkbDocumentItem.id) {
          // update item
          const recordId = this.wkbDocumentItem.id;
          delete this.wkbDocumentItem.id;
          if (this.wkbDocumentItem.default instanceof File) {
            await FileHandlerService.handleFile(
              this.wkbDocumentItem.default
            ).then((value) => {
              this.wkbDocumentItem.default = value;
            });
          }
          this.$store
            .dispatch('updateWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS',
              recordId,
              body: {
                record: this.wkbDocumentItem,
              },
            })
            .then(() => {
              this.closeWKBDocumentDialog();
            });
        } else {
          // create item
          if (this.wkbDocumentItem.default instanceof File) {
            await FileHandlerService.handleFile(
              this.wkbDocumentItem.default
            ).then((value) => {
              this.wkbDocumentItem.default = value;
            });
          }
          this.$store
            .dispatch('createWkbLibraryRecord', {
              table: 'CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS',
              body: {
                record: this.wkbDocumentItem,
              },
            })
            .then(() => {
              this.closeWKBDocumentDialog();
            });
        }
      }
    },
    deleteWkbDocumentRecord() {
      this.$store
        .dispatch('deleteWkbLibraryRecord', {
          table: 'CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS',
          recordId: this.wkbDocumentItem.id,
        })
        .then(() => {
          this.closeWKBDocumentDialog();
        });
    },
  },
};
</script>

<style scoped></style>
